.questionBanner {
    display: flex;
    margin-top: 2rem;
}

.questionLine {
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: -8px;
    margin-right: 1rem;
}

    .questionLine span {
        background: #fff;
        padding: 0 10px;
    }

.addRemoveAnswerOption {
    display: flex;
    margin-top: 1rem;
    margin-left: 1rem;
}

.questionMenu {
    margin-left: 1.5rem;
    margin-top: 0.5rem;
}

.surveySubmitAccept {
    padding-bottom: 2rem;
    width: 50%;
}

.surveySubmitAccepted {
    color: var(--color-primary);
}

.surveySubmitStatusAccepted {
    color: var(--color-primary);
    font-weight: bold;
}

.surveySubmitStatusPending {
    font-weight: bold;
}

.surveySubmitStatusPublic {
    color: #6699ff;
    font-weight: bold;
}

.surveyControl {
    display: flex;
    justify-content: left;
    gap: 1rem;
    flex-direction: column;
}

    .surveyControl div {
        display: flex;
        gap: 1rem;
    }

@media screen and (max-width: 1000px) {
    .surveySubmitAccept {
        width: 100%;
    }
}