.topBanner {
    height: 12rem;
    width: 100%;
    background-size: cover;
}

.topBannerImage1 {
    background-image: url('../../../assets/top1.jpg');
    background-position: top;
}

.topBannerImage2 {
    background-image: url('../../../assets/top2.jpg');
    background-position: center;
}

.topBannerImage3 {
    background-image: url('../../../assets/top3.jpg');
    background-position: top;
}

.topBannerImage4 {
    background-image: url('../../../assets/top4.jpg');
    background-position: bottom;
}

.topBannerImage5 {
    background-image: url('../../../assets/top5.jpg');
    background-position: top;
}

.logoImage {
    display: none;
}

.topRoute {
    width: calc(100%);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    display: flex;
    justify-content: space-between;
}

.topRouteItem {
    display: flex;
    padding: 0.5rem;
    align-items: center;
}

    .topRouteItem > span {
        display: block;
        font-size: 18px;
        font-weight: 900;
    }

        .topRouteItem > span > a {
            font-size: 18px;
            font-weight: 900;
            text-decoration: none;
            color: black;
        }

.topRouteMobileShow {
    display: none;
}

.version {
    color: white;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

    .version > span {
        margin-bottom: 0.25rem;
        margin-left: 0.5rem;
    }

.back {
    cursor: pointer;
    padding-right: 0.5rem;
}

.link:hover {
    color: var(--color-primary);
}

@media screen and (max-width: 800px) {
    .logoImage {
        display: block;
        height: 5rem;
    }

    .logoImageContainer {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 1rem;
    }
    
    .topBanner {
        height: 6rem;
        display: flex;
        justify-content: space-between;
    }

    .topRouteMobileHide {
        display: none;
    }

    .topRouteMobileShow {
        display: block;
    }

    .topRouteItem {
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
    }

    .version > span {
        margin-bottom: 0.25rem;
        margin-right: 0.5rem;
    }
}