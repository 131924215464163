.surveyContent {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
}

.survey {
    padding: 1.5rem;
}

.surveyLogin {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.surveyInfo {
    display: flex;
    justify-content: center;
}

    .surveyInfo > div > div {
        width: 400px;
        padding-top: 2rem;
        padding-bottom: 1rem;
        margin-left: auto;
        margin-right: auto;
    }

        .surveyInfo > div > div > img {
            width: 200px;
            padding-left: 100px;
            padding-right: 100px;
        }

    .surveyInfo > div > h1, .surveyInfo > div > p {
        text-align: center;
    }